export class Sector {
  id: string;
  name: string;
  description?: string;
  createdAt: Date;
  updatedAt: Date;

  constructor(
    id: string,
    name: string,
    createdAt: Date,
    updatedAt: Date,
    description?: string,
  ) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }

  static fromJson(id: string, json: any): Sector {
    return new Sector(
      id,
      json["name"],
      json["created_at"],
      json["updated_at"],
      json["description"],
    );
  }

  toJson(): any {
    const fields = {
      name: this.name,
      description: this.description,
      created_at: this.createdAt,
      updated_at: this.updatedAt,
    };

    const newObj = {};
    for (const key in fields) {
      if (fields[key] !== undefined) {
        newObj[key] = fields[key];
      }
    }

    return newObj;
  }
}
