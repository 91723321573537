import { ITask } from "../interfaces/ITask";
import { TaskRepository } from "repositories/TaskRepository";
import Task from "entities/Task";

export class TaskService {
  private taskRepository: TaskRepository = new TaskRepository();

  convertToEntity(task: ITask): Task {
    return new Task(
      task.name,
      task.manager,
      task.isActive,
      task.createdAt,
      task.updatedAt,
      task.description,
      task.expectedStart,
      task.expectedEnd,
      task.expectedHours,
      task.assignedToUsers,
    );
  }

  convertToInterface(task: Task): ITask {
    return <ITask>{
      description: task.description,
      expectedStart: task.expectedStart,
      expectedEnd: task.expectedEnd,
      expectedHours: task.expectedHours,
      id: task.id,
      assignedToUsers: task.assignedToUsers,
      isActive: task.isActive,
      manager: task.manager,
      name: task.name,
      createdAt: task.createdAt,
      updatedAt: task.updatedAt,
    };
  }

  public async getProjectTasks(projectId: string): Promise<ITask[]> {
    let result: ITask[] = [];
    result = (await this.taskRepository.getAllInProject(projectId)).map(
      (task) => this.convertToInterface(task),
    );
    return result.sort((a, b) => a.name.localeCompare(b.name));
  }

  public async getAssignedProjectTasks(
    projectId: string,
    userId: string,
  ): Promise<ITask[]> {
    let result: ITask[] = [];
    result = (await this.taskRepository.getAllInProject(projectId)).map(
      (task) => this.convertToInterface(task),
    );
    return result
      .filter((item) =>
        item.assignedToUsers?.some(
          (assignedToUser) => assignedToUser.id === userId,
        ),
      )
      .sort((a, b) => a.name.localeCompare(b.name));
  }
  // TODO: rewirte
  public async updateTask(
    projectId: string,
    task: ITask,
  ): Promise<string | undefined> {
    const entity = this.convertToEntity(task);
    if (entity && task.id) {
      return await this.taskRepository.update(
        projectId,
        task.id,
        entity.toJson(),
      );
    }
    return;
  }

  // TODO: rewirte
  public async createTask(
    projectId: string,
    task: ITask,
  ): Promise<string | undefined> {
    const entity = this.convertToEntity(task);
    if (entity) return await this.taskRepository.create(projectId, entity);
  }

  // TODO: rewirte
  public async toggleIsActiveTask(
    projectId: string,
    task: ITask,
    taskId: string,
  ): Promise<any> {
    const result = await this.taskRepository.update(projectId, taskId, {
      is_active: !task.isActive,
    });
    return result;
  }
}
