import Customer from "entities/Customer";
import { ECollections as coll, FirestoreService } from "../firebase";
import {
  DocumentData,
  QueryDocumentSnapshot,
  SnapshotOptions,
} from "firebase/firestore";

interface CUSTOMER_FILTER {
  id?: string;
  name?: string;
}

export class CustomerRepository {
  private firestore: FirestoreService;

  constructor() {
    this.firestore = new FirestoreService();
  }

  public customerConverter = {
    toFirestore(customer: Customer): DocumentData {
      return customer.toJson;
    },
    fromFirestore(
      snapshot: QueryDocumentSnapshot,
      options: SnapshotOptions,
    ): Customer {
      const data = snapshot.data(options);
      return new Customer(
        data.name,
        data.is_active,
        data.created_at.toDate(),
        data.updated_at.toDate(),
        data.phone_number,
        data.email,
        data.cnpj,
        data.segment,
        data.website,
        data.logo_path,
        data.contact,
        snapshot.id,
      );
    },
  };

  public async find(filter: CUSTOMER_FILTER): Promise<Customer[]> {
    let customerResult: Customer[] = [];

    if (filter.name) {
      const customerDoc = await this.firestore.findDocumentByQuery(
        coll.CUSTOMER,
        "name",
        "==",
        filter.name,
      );
      customerResult = customerDoc.map((customer) =>
        Customer.fromJson(customer.data, customer.id),
      );
    }

    return customerResult;
  }

  public async get(id: string): Promise<Customer | undefined> {
    const result = await this.firestore.getByDocId<Customer>(
      coll.CUSTOMER,
      id,
      this.customerConverter,
    );
    if (result) return result;
  }

  public async create(customer: Customer): Promise<Customer> {
    customer.updatedAt = new Date();
    customer.createdAt = new Date();

    const result = this.firestore.setDocument(coll.CUSTOMER, customer.toJson());
    return result;
  }

  public async update(id: string, customerData: Customer): Promise<Customer> {
    const firebase = new FirestoreService();
    customerData.updatedAt = new Date();

    const customerDocument = await firebase.updateDocument(
      coll.CUSTOMER,
      customerData.toJson(),
      id,
    );

    return customerDocument;
  }

  public async getAll(): Promise<Customer[]> {
    const customers = await this.firestore.getAllDocuments(coll.CUSTOMER);

    return customers.map((customer) =>
      Customer.fromJson(customer.data, customer.id),
    );
  }
}
