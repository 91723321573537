export default class Customer {
  id?: string;
  name: string;
  isActive: boolean;
  phoneNumber?: string;
  email?: string;
  cnpj?: string;
  segment?: string;
  website?: string;
  logoPath?: string;
  contact?: {
    name?: string;
    phoneNumber?: string;
    email?: string;
    function?: string;
  };
  createdAt: Date;
  updatedAt: Date;

  constructor(
    name: string,
    isActive: boolean,
    createdAt: Date,
    updatedAt: Date,
    phoneNumber?: string,
    email?: string,
    cnpj?: string,
    segment?: string,
    website?: string,
    logoPath?: string,
    contact?: {
      name?: string;
      phoneNumber?: string;
      email?: string;
      function?: string;
    },
    id?: string,
  ) {
    this.name = name;
    this.isActive = isActive;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.phoneNumber = phoneNumber;
    this.email = email;
    this.cnpj = cnpj;
    this.segment = segment;
    this.website = website;
    this.logoPath = logoPath;
    this.contact = contact;
    this.id = id;
  }

  static fromJson(json: any, id?: string): Customer {
    return new Customer(
      json["name"],
      json["is_active"],
      json["created_at"],
      json["updated_at"],
      json["phone_number"],
      json["email"],
      json["cnpj"],
      json["segment"],
      json["website"],
      json["logo_path"],
      json["contact"],
      id,
    );
  }

  toJson(): any {
    const fields = {
      name: this.name,
      is_active: this.isActive,
      created_at: this.createdAt,
      updated_at: this.updatedAt,
      phone_number: this.phoneNumber,
      email: this.email,
      cnpj: this.cnpj,
      segment: this.segment,
      website: this.website,
      logo_path: this.logoPath,
      contact: this.contact,
    };

    const newObj = {};
    for (const key in fields) {
      if (fields[key] !== undefined) {
        newObj[key] = fields[key];
      }
    }

    return newObj;
  }
}
