import { handleLogout } from "../../modules/auth/actions";

import timeSheetIcon from "assets/img/timeSheetIcon.svg";
// import configIcon from 'assets/img/configIcon.svg'
// import myDataIcon from 'assets/img/myDataIcon.svg'
// import documentsIcon from 'assets/img/documentsIcon.svg'
import rhIcon from "assets/img/rhIcon.svg";
import faqIcon from "assets/img/faqIcon.svg";

export const menus = [
  {
    id: "1",
    name: "Administrativo",
    link: "",
    role: ["Manager", "Admin"],
    background: "#F58434",
  },

  {
    id: "2",
    name: "RH",
    link: "",
    role: ["super_admin", "rh"],
    icon: rhIcon,
    subItems: [
      {
        name: "Criar acesso de usuário",
        titlePage: "Usuários - Listagem de usuários",
        link: "/users",
        role: ["super_admin", "rh"],
      },
    ],
  },

  {
    id: "2",
    name: "Timesheet",
    link: "",
    role: ["Manager", "Admin"],
    icon: timeSheetIcon,

    subItems: [
      {
        name: "Aprovação de horas",
        titlePage: "TimeSheet - Aprovação de horas",
        link: "/timesheet/approval",
        role: ["Manager", "Admin"],
      },
      {
        name: "Cadastro de clientes",
        titlePage: "Clientes - Listagem",
        link: "/clients",
        role: ["Admin"],
      },

      {
        name: "Criar projetos",
        titlePage: "Projetos - Listagem de projetos",
        link: "/projects",
        role: ["Manager", "Admin"],
      },
    ],
  },

  {
    id: "3",
    name: "Menu principal",
    link: "",
    role: ["Manager", "Admin"],
    background: "#79A23F",
  },

  {
    id: "3",
    name: "TimeSheet",
    link: "/timesheet/note",
    role: ["User", "Manager", "Admin"],
    titlePage: "TimeSheet - Apontamento de horas",
    icon: timeSheetIcon,
  },
  {
    id: "3",
    name: "FAQ",
    link: "/faq",
    role: ["User", "Manager", "Admin"],
    icon: faqIcon,
  },

  {
    id: "0",
    name: "Sair do Web App",
    link: "/",
    role: ["User", "Manager", "Admin"],
    onClick: handleLogout,
  },
];
