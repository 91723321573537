import { Flex, Tabs, Text, useToast } from "@chakra-ui/react";
import { TabData } from "components/CustomTabs/types";
import HeaderInside from "layouts/HeaderInside";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import TabListContainer from "components/CustomTabs/TabListContainer";
import TabPanelsContainer from "components/CustomTabs/TabPanelsContainer";
import { RegistrationData } from "./registrationData";

import { UserService } from "services/UserService";
import { IUser } from "interfaces/IUser";
import { Documents } from "./documents";
import ModalWrapper from "components/Modal";
import { ModalConfirmDelete } from "components/Modal/ModalConfirmDelete/confirmDeleteModal";

export const CreateOrUpdateUser: React.FC = () => {
  const [openModalCofirmDelete, setOpenModalCofirmDelete] = useState(false);
  const userService = new UserService();

  const [tabIndex, setTabIndex] = useState(0);
  const { id } = useParams<{ id: string }>();
  const toast = useToast();
  const history = useHistory();

  const handleOpenModalConfirm = () => {
    setOpenModalCofirmDelete((prev) => !prev);
  };

  const handleTabChange = (index: number) => {
    setTabIndex(index);
  };

  const [user, setUser] = useState<IUser>({
    createdAt: new Date(),
    updatedAt: new Date(),
    firstName: "",
    lastName: "",
    email: "",
    sector: {
      id: "",
      name: "",
    },
    role: {
      id: "user",
      name: "Usuário",
    },
  } as IUser);

  const handleFindUserById = useCallback(
    async (id) => {
      if (id !== "0") {
        const response: any = await userService.getUserById(id);

        if (response.id) {
          setUser({
            ...user,
            ...response,
            startDate: response?.startDate?.toDate(),
            bornDate: response?.bornDate?.toDate(),
            admissionDate: response?.admissionDate?.toDate(),
            created_at: response?.created_at?.toDate(),
            creationDate: response?.creationDate?.toDate(),
            updated_at: response?.updated_at?.toDate(),
            benefitStartAt: response?.benefitStartAt?.toDate(),
            id: id,
          } as IUser);

          return;
        }

        setUser({
          createdAt: new Date(),
          updatedAt: new Date(),

          sector: {
            id: "",
            name: "",
          },
          role: {
            id: "user",
            name: "Usuário",
          },
        } as IUser);

        toast({
          title: "Ocorreu um erro ao buscar o usuário!",
          description: "",
          status: "error",
          duration: 4000,
          isClosable: true,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [toast, user],
  );

  useEffect(() => {
    handleFindUserById(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const tabs: TabData[] = [
    {
      label: "Dados cadastrais",
      content: (
        <>
          <RegistrationData selectedUserId={id} user={user} />
        </>
      ),
    },
    {
      label: "Documentos",
      content: (
        <>
          <Documents user={user} />
        </>
      ),
    },
  ];

  const handleToggleStatusUser = useCallback(
    async (status: boolean) => {
      const response = await userService.updateUser(id, {
        isActive: !status,
      } as IUser);

      if (!response.id || !response) {
        toast({
          title: "Ocorreu um erro!",
          description: "Usuário não foi desligado!",
          status: "warning",
          duration: 4000,
          isClosable: true,
        });
        return;
      }
      toast({
        title: "Dados atualizados com sucesso.",
        description: `Colaborador ${status ? "desligado" : "reativado"}!`,
        status: "success",
        duration: 4000,
        isClosable: true,
      });
      history.push("/users");
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history, id, toast],
  );

  return (
    <>
      <HeaderInside>
        <Flex justify={"space-between"} align={"center"} flex="1" gap="4">
          <Flex align={"center"} justify={"center"} gap={3} cursor={"pointer"}>
            <Text fontSize={["lg"]} cursor={"pointer"}>
              {" "}
              Criação de acesso
            </Text>
          </Flex>
        </Flex>
      </HeaderInside>
      <Tabs index={tabIndex} onChange={handleTabChange} p="2">
        <TabListContainer
          tabs={tabs}
          onTabChange={handleTabChange}
          activeTabIndex={tabIndex}
          justifyContent={"center"}
          showExcludeUser={id !== "0"}
          handleOpenModal={handleOpenModalConfirm}
          status={user.isActive}
        />
        <TabPanelsContainer tabs={tabs} />
      </Tabs>

      <ModalWrapper
        isOpen={openModalCofirmDelete}
        onClose={handleOpenModalConfirm}
      >
        <ModalConfirmDelete
          handleClose={handleOpenModalConfirm}
          textModal={`O(A) colaborador(a) ${user.firstName} ${user.lastName} será ${user.isActive ? "desligado(a)" : "reativado(a)"} .`}
          handleDelete={async () => {
            await handleToggleStatusUser(user.isActive);
          }}
        />
      </ModalWrapper>
    </>
  );
};
