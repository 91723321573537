import { IProject } from "../interfaces/IProject";
import { IProjectTypes } from "interfaces/IProjectTypes";
import { ProjectRepository } from "repositories/ProjectRepository";
import { Project, ProjectSteps, ProjectRisk } from "entities/Project";

export class ProjectService {
  private projectRepository = new ProjectRepository();

  public steps = new Set([
    "Backlog",
    "Discovery & Assessment",
    "Planejamento",
    "Levantamento Técnico",
    "Desenvolvimento",
    "Teste Integrado",
    "Homologação",
    "Go Live",
    "Finalizado",
    "Suporte Técnico",
    "Alocação",
  ]);

  public risks = new Set(["baixo", "médio", "neutro", "alto", "muito alto"]);

  convertToEntity(project: IProject): Project | undefined {
    if (this.steps.has(project.currentStep) && this.risks.has(project.risk)) {
      return new Project(
        project.name,
        project.code,
        project.managers,
        project.currentStep as ProjectSteps,
        project.billable,
        project.isBlocked,
        project.risk as ProjectRisk,
        project.type,
        project.isActive,
        project.customer,
        project.createdAt,
        project.updatedAt,
        project.description,
        project.successCriteria,
        project.learningSummary,
        project.startedAt,
        project.endedAt,
        project.expectedStart,
        project.expectedEnd,
        project.additionalHours,
        project.soldHours,
        project.outcome,
        project.revenueRecognition,
        project.assignedToUsers,
      );
    }
  }

  convertToInterface(project: Project): IProject {
    return {
      id: project.id,
      name: project.name,
      description: project.description,
      code: project.code,
      managers: project.managers,
      currentStep: project.currentStep,
      isBlocked: project.isBlocked,
      learningSummary: project.learningSummary,
      successCriteria: project.successCriteria,
      createdAt: project.createdAt,
      updatedAt: project.updatedAt,
      startedAt: project.startedAt,
      endedAt: project.endedAt,
      expectedStart: project.expectedStart,
      expectedEnd: project.expectedEnd,
      billable: project.billable,
      outcome: project.outcome,
      assignedToUsers: project.assignedToUsers,
      risk: project.risk,
      revenueRecognition: project.revenueRecognition,
      soldHours: project.soldHours,
      isActive: project.isActive,
      type: project.type,
      customer: project.customer,
    };
  }

  public async getProjectsByEmail(userEmail: string): Promise<IProject[]> {
    const result = await this.projectRepository.find({ email: userEmail });
    return result.map((projects) => this.convertToInterface(projects));
  }

  public async getAllProjects(): Promise<IProject[]> {
    const result = await this.projectRepository.getAll();
    return result.map((project) => this.convertToInterface(project));
  }

  public async getProjectsById(projectId: string): Promise<IProject> {
    const result = await this.projectRepository.get(projectId);
    return this.convertToInterface(result);
  }

  public async getProjectType(): Promise<IProjectTypes[]> {
    const result = await this.projectRepository.getAllTypes();
    return result.map((type) => {
      return <IProjectTypes>{
        id: type.id,
        name: type.name,
        created_at: type.createdAt,
        description: type.description,
        updated_at: type.updatedAt,
      };
    });
  }

  public async createProject(project: IProject): Promise<string | undefined> {
    const entity = this.convertToEntity(project);

    if (entity) return await this.projectRepository.create(entity);
  }

  public async updateProject(project: IProject, id: string): Promise<any> {
    const entity = this.convertToEntity(project);
    let result;

    if (entity) {
      result = await this.projectRepository.update(id, entity);
    }
    return result;
  }

  public async reverseIsActiveProject(
    project: IProject,
    id: string,
  ): Promise<any> {
    project.isActive = !project.isActive;

    const entity = this.convertToEntity(project);
    let result;

    if (entity) {
      result = await this.projectRepository.update(id, entity);
    }
    return result;
  }
}
